.card-container {
    perspective: 1000px;
    width: 400px; /* Adjust as needed */
    height: 490px; /* Adjust as needed */
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.6s;
  }
  
  .card-inner {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
  }
  
  .card-front,
  .card-back {
    width: 100%;
    height: 100%;
    background: #e2e8f0;
    border-radius: 30px;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    box-sizing: border-box;
    transition: transform 0.6s;
  }

  .card-back {
    cursor: pointer;
  }
  
  @media screen and (min-width: 465px) {
    .card-back, card-front {
      padding: 10px;
    }
  }
  .card-front {
    z-index: 2;
    transform: rotateY(0deg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .card-back {
    transform: rotateY(180deg);
    text-align: center;
    overflow-y: auto; /* Allow scrolling if content overflows */
  }
  
  .card-container.flipped .card-front {
    transform: rotateY(180deg);
  }
  
  .card-container.flipped .card-back {
    transform: rotateY(360deg);
    z-index: 2;
  }
  

   /* Hide scrollbar for Chrome, Safari and Opera */
.no-scroll-bar::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scroll-bar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  } 
