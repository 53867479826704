@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@font-face {
  font-family: 'bespoke-bold';
  src: local('MyFont'), url(./assets/fonts/BespokeSerif-Bold.otf) format('otf');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}


/* CUSTOM CSS */
.cloudy-button {
  position: relative;
  background-color: #4a90e2; /* Blue color */
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 50%; /* Makes it circular */
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); /* Adds shadow */
}

.cloudy-button::before {
  content: '';
  position: absolute;
  top: -20px;
  left: -20px;
  width: 200%;
  height: 200%;
  background-color: white;
  opacity: 0.25;
  transform: rotate(45deg);
}


html::-webkit-scrollbar {
  width: 10px;
}

html::-webkit-scrollbar-track {
  background: rgb(179, 177, 177);
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb {
  background: #4C7C7C;
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb:hover {
  background: #4C7C7C;
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb:active {
  background: #4C7C7C;
  border-radius: 10px;
}